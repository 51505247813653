body.page-minimal {
  background: $body-bg url($public-page-bg);
  background-size: cover;
  background-attachment: fixed;

  footer {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black, 0 0 4px #000;
  }

  .public-page {
    height: 100vh;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
    justify-content: center;

    .card {
      width: 500px;

      .card-body {
        position: relative;

        .card-title {
          font-weight: bold;
        }

        .btn-float {
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: (0-($btn-float-size / 2));
          margin-right: (0-($btn-float-size / 2));
        }
      }

      @include media-breakpoint-only('xs') {
        width: calc(100% - 40px);
        margin-left: 0;
      }
    }
  }

  &.login-content,
  &.error-content {
    .card {
      .card-body {
        padding: ($card-padding-y * 2) ($card-padding-y * 2);
      }
    }
  }

  // Pulled from:
  // https://stackoverflow.com/questions/5474871/html-how-can-i-show-tooltip-only-when-ellipsis-is-activated

  .might-overflow {
    text-overflow: ellipsis;
    overflow : hidden;
    white-space: nowrap;

    &:hover {
      text-overflow: clip;
      white-space: normal;
      word-break: break-all;
    }
  }

  #station-history {
    .song {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      line-height: normal;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      .order {
        display: flex;
        flex-direction: column;
        width: 35px;
        justify-content: center;
        margin-right: 5px;
        text-align: center;
      }

      .art {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        margin-right: 5px;
      }

      .name {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
      }

      .date-played {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 4px 0 0 40px;
      }

      .break {
        flex-basis: 100%;
        height: 0;
      }

      @media (min-width:576px) {
        .date-played {
          margin-left: auto;
        }
        .break {
          display: none;
        }
      }
    }
  }
}
