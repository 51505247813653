// Colors
$theme-color-1: $light-theme-1 !default;
$theme-color-2: $light-theme-2 !default;
$theme-color-3: $light-theme-3 !default;
$theme-color-4: $light-theme-4 !default;

$text-color: $black !default;
$text-primary: $black-primary !default;
$text-secondary: $black-secondary !default;
$text-hint: $black-hint !default;
$text-divider: $black-divider !default;

$text-primary-opacity: $black-primary-opacity !default;
$text-secondary-opacity: $black-secondary-opacity !default;
$text-hint-opacity: $black-hint-opacity !default;
$text-divider-opacity: $black-divider-opacity !default;

$text-inverted-color: $white !default;
$text-inverted-primary: $white-primary !default;
$text-inverted-secondary: $white-secondary !default;
$text-inverted-hint: $white-hint !default;
$text-inverted-divider: $white-divider !default;

$text-inverted-primary-opacity: $white-primary-opacity !default;
$text-inverted-secondary-opacity: $white-secondary-opacity !default;
$text-inverted-hint-opacity: $white-hint-opacity !default;
$text-inverted-divider-opacity: $white-divider-opacity !default;

@if $theme == 'dark' {
  $theme-color-1: $dark-theme-1;
  $theme-color-2: $dark-theme-2;
  $theme-color-3: $dark-theme-3;
  $theme-color-4: $dark-theme-4;

  $text-color: $white;
  $text-primary: $white-primary;
  $text-secondary: $white-secondary;
  $text-hint: $white-hint;
  $text-divider: $white-divider;

  $text-primary-opacity: $white-primary-opacity !default;
  $text-secondary-opacity: $white-secondary-opacity !default;
  $text-hint-opacity: $white-hint-opacity !default;
  $text-divider-opacity: $white-divider-opacity !default;

  $text-inverted-color: $black;
  $text-inverted-primary: $black-primary;
  $text-inverted-secondary: $black-secondary;
  $text-inverted-hint: $black-hint;
  $text-inverted-divider: $black-divider;

  $text-inverted-primary-opacity: $black-primary-opacity !default;
  $text-inverted-secondary-opacity: $black-secondary-opacity !default;
  $text-inverted-hint-opacity: $black-hint-opacity !default;
  $text-inverted-divider-opacity: $black-divider-opacity !default;
}

// Alert
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-medium !default;
$alert-margin-y: $spacer !default;
$alert-padding-x: 1rem !default;
$alert-padding-y: 1rem !default;

// Badge
$badge-border-radius: $border-radius !default;
$badge-font-weight: $font-weight-medium !default;
$badge-padding-x: 0.5em !default;

// Body
$body-bg: $theme-color-1 !default;
$body-color: #5e5e5e !default;

@if $theme == 'dark' {
  $body-color: $material-color-grey-300;
}

// Breadcrumb
$breadcrumb-bg: $material-color-grey-100 !default;
$breadcrumb-border-radius: $border-radius !default;
$breadcrumb-height: 3.5rem !default;
$breadcrumb-inner-spacer-x: 0.5rem !default;
$breadcrumb-item-color: $text-secondary !default;
$breadcrumb-item-color-hover: $text-primary !default;
$breadcrumb-margin-y: $spacer !default;
$breadcrumb-padding-x: 1rem !default;
$breadcrumb-padding-y: 0.625rem !default;

@if $theme == 'dark' {
  $breadcrumb-bg: $dark-theme-3;
}

$breadcrumb-item-icon: str-replace(url('data:image/svg+xml;charset=utf8,<svg fill="#{$breadcrumb-item-color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>'), '#', '%23');

// Caret
$caret-bg: str-replace(url('data:image/svg+xml;charset=utf8,%3Csvg fill="#{$text-color}" fill-opacity="#{$text-secondary-opacity}" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M7 10l5 5 5-5z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E'), '#', '%23') !default;
$caret-width: .4rem !default;
$caret-vertical-align: .1rem !default;
$caret-spacing: $caret-width * 1.5 !default;

// Carousel
$carousel-caption-color: $white-primary !default;
$carousel-caption-width: 50% !default;
$carousel-control-bg: $white-divider !default;
$carousel-control-bg-hover: rgba(204, 204, 204, 0.25) !default;
$carousel-control-color: $white-primary !default;
$carousel-control-elevation-shadow: map-get($elevation-shadows, 6) !default;
$carousel-control-elevation-shadow-active: map-get($elevation-shadows, 12) !default;
$carousel-control-font-size: 0.8125rem !default;
$carousel-control-size: 2.5rem !default;
$carousel-indicator-bg: rgba(204, 204, 204, 0.25) !default;
$carousel-indicator-border-color: $white-divider !default;
$carousel-indicator-border-width: $border-width !default;
$carousel-indicator-size: 0.5rem !default;

$carousel-control-next-icon: str-replace(url('data:image/svg+xml;charset=utf8,<svg fill="#{$carousel-control-color}" xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/></svg>'), '#', '%23');
$carousel-control-prev-icon: str-replace(url('data:image/svg+xml;charset=utf8,<svg fill="#{$carousel-control-color}" xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/></svg>'), '#', '%23');

// Close
$close-color: $text-hint !default;
$close-color-hover: $text-primary !default;
$close-font-size: $material-icon-size !default;
$close-font-weight: $font-weight-light !default;

// Code
$code-bg: $material-color-grey-100 !default;
$code-border-radius: $border-radius !default;
$code-color: #bd4147 !default;
$code-font-size: 87.5% !default;
$code-padding-x: 0.4rem !default;
$code-padding-y: 0.2rem !default;

$kbd-bg: $black-primary !default;
$kbd-color: $white-primary !default;

$pre-color: $black-primary !default;
$pre-margin-y: $spacer !default;
$pre-scrollable-max-height: 340px !default;

@if $theme == 'dark' {
  $code-bg: $black-hint;
  $pre-color: $material-color-grey-500;
}

// Form
$custom-file-button-icon: str-replace(url('data:image/svg+xml;charset=utf8,<svg fill="#{$text-hint}" xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M2 12.5C2 9.46 4.46 7 7.5 7H18c2.21 0 4 1.79 4 4s-1.79 4-4 4H9.5C8.12 15 7 13.88 7 12.5S8.12 10 9.5 10H17v2H9.41c-.55 0-.55 1 0 1H18c1.1 0 2-.9 2-2s-.9-2-2-2H7.5C5.57 9 4 10.57 4 12.5S5.57 16 7.5 16H17v2H7.5C4.46 18 2 15.54 2 12.5z"/></svg>'), '#', '%23');

$form-check-inline-margin-x: $spacer-sm !default;
$form-check-input-gutter: 1.25rem !default;

$form-feedback-invalid-color: theme-color(danger) !default;
$form-feedback-valid-color: theme-color(success) !default;

$form-group-margin-y: $spacer !default;

$input-group-inner-spacer-x: 1rem !default;

// Jumbotron
$jumbotron-bg: $white-primary !default;
$jumbotron-border-radius: $border-radius !default;
$jumbotron-color: $text-primary !default;
$jumbotron-elevation-shadow: map-get($elevation-shadows, 2) !default;
$jumbotron-padding-x: 2rem !default;
$jumbotron-padding-y: 3rem !default;

@if $theme == 'dark' {
  $jumbotron-bg: $dark-theme-2;
}

// Link
$link-color: theme-color(primary) !default;
$link-color-hover: theme-color-light(primary) !default;

$link-decoration: none !default;
$link-decoration-hover: underline !default;

// Misc
$blockquote-border-color: theme-color(primary) !default;
$blockquote-border-width: 0.3125rem !default;
$blockquote-small-color: $text-hint !default;

$dt-font-weight: $font-weight-medium !default;

$figure-caption-color: $text-hint !default;
$figure-img-margin-y: $spacer-sm !default;

$headings-color: $black-primary !default;
$headings-font-family: inherit !default;
$headings-margin-y: $spacer-sm !default;

$hr-border-color: $border-color !default;
$hr-border-width: $border-width !default;

$list-inline-inner-spacer-x: $spacer-sm !default;

$mark-bg: $material-color-yellow-500 !default;
$mark-color: $black-primary !default;
$mark-padding: 0.2em !default;

$paragraph-margin-y: $spacer !default;

$small-font-size: 80% !default;

$table-caption-color: $text-hint !default;

$text-muted: $text-hint !default;

$thumbnail-box-shadow: map-get($shadows, 2) !default;

$webkit-tap-highlight-color: rgba(0, 0, 0, 0) !default;

@if $theme == 'dark' {
  $headings-color: inherit;
}

// Nav
$nav-link-color-disabled: $text-hint !default;
$nav-link-padding-x: 1rem !default;
$nav-link-padding-y: 0.5rem !default;

$nav-pills-bg-hover: $black-divider !default;
$nav-pills-border-radius: $border-radius !default;
$nav-pills-color: $text-secondary !default;
$nav-pills-color-active: $text-primary !default;
$nav-pills-link-opacity: 0.7 !default;

// Pagination
$pagination-bg: $material-color-grey-100 !default;
$pagination-border-radius: $border-radius !default;
$pagination-color: $text-primary !default;
$pagination-color-disabled: $text-hint !default;
$pagination-inner-spacer-x: 1px !default;
$pagination-padding-x: 0.5rem !default;
$pagination-padding-y: 0.625rem !default;

@if $theme == 'dark' {
  $pagination-bg: $dark-theme-3;
}

// Popover
$popover-bg: $white !default;
$popover-border-radius: $border-radius !default;
$popover-breakpoint: md !default;
$popover-elevation-shadow: map-get($elevation-shadows, 24) !default;
$popover-font-size: $font-size-base !default;
$popover-margin: 1.5rem !default;
$popover-margin-desktop: 0.875rem !default;
$popover-max-width: 17.5rem !default;
$popover-padding-x: 1.5rem !default;
$popover-padding-y: 1.25rem !default;

@if $theme == 'dark' {
  $popover-bg: $dark-theme-1;
}

// Print
$print-body-min-width: map-get($grid-breakpoints, 'lg') !default;
$print-page-size: a3 !default;

// Spinner
$spinner-width: 2rem !default;
$spinner-height: $spinner-width !default;
$spinner-border-width: .25em !default;

$spinner-width-sm: 1rem !default;
$spinner-height-sm: $spinner-width-sm !default;
$spinner-border-width-sm: .2em !default;
