.CodeMirror {
  background: $card-bg;
  border: 1px solid $textfield-border-color;
  border-radius: 4px;
  color: $textfield-color;

  .CodeMirror-gutters {
    background: $navdrawer-header-bg;
    border-right: 1px solid $textfield-border-color;
  }

  .CodeMirror-scroll {
    padding-top: 10px;
    margin-bottom: -60px;
  }

  pre.CodeMirror-line,
  pre.CodeMirror-line-like {
    font-size: .8rem;
  }

  .CodeMirror-guttermarker,
  .CodeMirror-guttermarker-subtle,
  .CodeMirror-linenumber {
    color: $textfield-color;
    font-size: .8rem;
  }

  @if ($theme == 'dark') {
    .CodeMirror-cursor {
      border-left: 1px solid #FFCC00;
    }

    div.CodeMirror-selected,
    &.CodeMirror-focused div.CodeMirror-selected,
    .CodeMirror-line::selection,
    .CodeMirror-line > span::selection,
    .CodeMirror-line > span > span::selection,
    .CodeMirror-line::-moz-selection,
    .CodeMirror-line > span::-moz-selection,
    .CodeMirror-line > span > span::-moz-selection {
      background: rgba(128, 203, 196, 0.2);
    }

    .CodeMirror-activeline-background {
      background: rgba(0, 0, 0, 0.5);
    }

    .CodeMirror-matchingbracket {
      text-decoration: underline;
      color: white !important;
    }

    .cm-keyword {
      color: #C792EA;
    }

    .cm-operator {
      color: #89DDFF;
    }

    .cm-variable-2 {
      color: #EEFFFF;
    }

    .cm-variable-3,
    .cm-type {
      color: #f07178;
    }

    .cm-builtin {
      color: #FFCB6B;
    }

    .cm-atom {
      color: #F78C6C;
    }

    .cm-number {
      color: #FF5370;
    }

    .cm-def {
      color: #82AAFF;
    }

    .cm-string {
      color: #C3E88D;
    }

    .cm-string-2 {
      color: #f07178;
    }

    .cm-comment {
      color: #546E7A;
    }

    .cm-variable {
      color: #f07178;
    }

    .cm-tag {
      color: #FF5370;
    }

    .cm-meta {
      color: #FFCB6B;
    }

    .cm-attribute {
      color: #C792EA;
    }

    .cm-property {
      color: #C792EA;
    }

    .cm-qualifier {
      color: #DECB6B;
    }

    .cm-variable-3,
    .cm-type {
      color: #DECB6B;
    }

    .cm-error {
      color: rgba(255, 255, 255, 1.0);
      background-color: #FF5370;
    }
  }
}

.CodeMirror-sizer {
  cursor: text;
  height: 100%;
  margin-bottom: 0 !important;
  padding-bottom: 16px !important;
}