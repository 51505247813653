.daterangepicker {
  background: $menu-bg;
  border: 1px solid $menu-divider-bg;

  .ranges {
    li:hover {
      background: $menu-link-bg-hover;
    }
  }

  &.show-ranges .drp-calendar.left {
    border-left: 1px solid $menu-divider-bg;
  }
  .drp-buttons {
    border-top: 1px solid $menu-divider-bg;
  }

  &:before {
    border: 0;
  }
  &:after {
    border-bottom-color: $menu-bg;
  }

  .calendar-table {
    background: none;
    border: 0;
  }

  td.off {
    background: $navdrawer-header-bg;
  }

  td.available:hover,
  th.available:hover {
    background-color: $navdrawer-nav-link-bg-hover;
  }
}
