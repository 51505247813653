#footer {
  opacity: .5;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  height: 75px;

  padding-top: 20px;
  padding-bottom: 15px;
}

@include media-breakpoint-up(lg) {
  body.has-sidebar #footer {
    left: $navdrawer-width;
  }
}
